<template>
  <div v-show="store.ready" class="mx-auto max-w-[400px] py-8 h-screen grid grid-rows-3">
    <ToastController />
    <div class="flex justify-center">
      <IconLogo name="logo" />
    </div>

    <div>
      <slot />
    </div>
    <div class="flex justify-center">
      <SharedFooter class="self-end" />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useApplicationStore } from '~/store/application';

const store = useApplicationStore();
useHead({
  titleTemplate: titleChunk => {
    return titleChunk ? `${titleChunk} - OrderProtection` : 'Welcome - OrderProtection';
  },
});
</script>
